import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getPacket, getRelatedPackets } from 'api';
import { setSeenPacket, setActivePacket } from 'actions';
import { calculateRelatedArticle } from 'helpers/Utils';
import { getLevel } from 'helpers/level-utils';
import PacketHeader from 'components/common/packet-header/packet-header';
import Vorlese from 'components/common/vorlese';
import SimpleArticle from 'components/common/assets/SimpleArticle';
import AudioPlayer from 'components/common/audio-player';
import RelatedArticles from 'components/common/related-articles/related-articles';
import PacketAuthor from 'components/common/author/packet';
import ExerciseSection from 'components/common/exercise-section/exercise-section';
import { SlotHeader } from 'components/common/SlotHeader';
import useCurrentPacket from 'hooks/useCurrentPacket';
import useCurrentRelatedArticles from 'hooks/useCurrentRelatedArticles';

const SinglePacket = ({
  match = { params: { nid: 0 } },
  entries,
  loading,
  getPacketAction,
  seen,
  pool,
  setActivePacketAction,
  activePacketID,
  getRelatedPacketsAction,
}) => {
  const { params = {} } = match;
  const { nid = 0 } = params;
  const packet = useCurrentPacket();
  const relatedArticles = useCurrentRelatedArticles();
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (nid !== activePacketID && pool.length) {
      dispatch(setSeenPacket(nid));

      dispatch(setActivePacketAction(nid));
      let raNids = calculateRelatedArticle(seen, pool, nid);
      getRelatedPacketsAction(raNids);
      if (raNids.poolEnd) {
        dispatch(setSeenPacket(0));
      }
    }

    if (!loading && !entries.hasOwnProperty(nid) && nid > 0) {
      getPacketAction(nid);
    }
    window.scrollTo(0, 0);

    return () => {};
  }, [nid, pool, activePacketID, seen]);

  if (loading || !packet) {
    return <div className="loading" />;
  }

  let slotsRender = [];
  let sectionExercises = false;

  const isTypeSimpleArticle = (type) => type === 'asset_simple_article';
  const isTypeExercise = (type) => type === 'asset_h5p_ubung';
  const isTypeAudioTrack = (type) => type === 'asset_audiotrack';

  /* eslint-disable array-callback-return */
  if (packet.hasOwnProperty('slots')) {
    packet['slots'].map((s, contor) => {
      if (s.hasOwnProperty('rows')) {
        s['rows'].map((r, rContor) => {
          slotsRender.push(
            <SlotHeader
              slotId={s['slotId']}
              slotName={s['name']}
              key={`slot-row-${contor}-${rContor}`}
            />
          );

          if (isTypeSimpleArticle(r['type'])) {
            slotsRender.push(
              <SimpleArticle
                key={`asset_simple_article-${contor}-${rContor}`}
                data={r}
              />
            );
          }
          if (isTypeExercise(r['type'])) {
            if (!sectionExercises) {
              slotsRender.push(<ExerciseSection key={'section_exercises'} />);
            }
            sectionExercises = true;
          }

          if (isTypeAudioTrack(r['type'])) {
            if (!sectionExercises) {
              slotsRender.push(
                <ExerciseSection
                  key={`section_audio_player-${contor}-${rContor}`}
                />
              );
            }
            slotsRender.push(
              <AudioPlayer
                key={`asset_audiotrack-${contor}-${rContor}`}
                data={packet}
              />
            );
          }
        });
      }
    });
  }

  const level = getLevel(packet.level.id);

  return (
    <div key={nid}>
      <PacketHeader
        type={packet.mainImage.type}
        imageUrl={packet.mainImage.url}
        intro={packet.vorspann}
        title={packet.title}
        levelLabel={level.label}
        levelType={level.type}
        readingTime={7}
        exerciseTime={12}
      />
      {packet.hasOwnProperty('vorlese') ? (
        <Vorlese data={packet['vorlese']} />
      ) : null}
      <div className={'container-main-section'}>{slotsRender}</div>
      <PacketAuthor data={packet.author} />
      <RelatedArticles
        articles={relatedArticles.entries}
        loading={relatedArticles.loading}
      />
    </div>
  );
};

const mapStateToProps = ({ packet }) => {
  const { loading, entries, seen, pool, activePacketID } = packet;
  return { loading, entries, seen, pool, activePacketID };
};

const mapActionsToProps = (dispatch) => {
  return {
    getPacketAction: getPacket(dispatch),
    setSeenPacketAction: setSeenPacket,
    setActivePacketAction: setActivePacket,
    getRelatedPacketsAction: getRelatedPackets(dispatch),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(SinglePacket);
