import {
  GET_PACKET,
  GET_PACKET_SUCCESS,
  GET_PACKET_ERROR,
  GET_POOL_PACKETS,
  GET_POOL_PACKETS_SUCCESS,
  GET_POOL_PACKETS_ERROR,
  SET_SEEN_PACKET,
  SET_ACTIVE_PACKET,
  GET_RELATED_PACKET,
  GET_RELATED_PACKET_SUCCESS,
  GET_RELATED_PACKET_ERROR,
} from '../actions/types';

import { getSeenPackets, setSeenPackets } from '../helpers/Utils';

const INIT_STATE = {
  activePacketID: 0,
  entries: {},
  seen: getSeenPackets(),
  pool: [],
  // pool: ["4040", "3952", "3264", "3940", "4036", "3854", "3963", "4047", "4044", "3783", "3940"],
  loading: false,
  error: '',
  relatedArticles: [],
  relatedArticlesLoading: false,
  relatedArticlesError: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PACKET:
      return { ...state, loading: true, error: '' };

    case GET_PACKET_SUCCESS:
      return {
        ...state,
        loading: false,
        entries: {
          ...state.entries,
          [action.payload.nid]: action.payload,
        },
        error: '',
      };

    case GET_PACKET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case GET_POOL_PACKETS:
      return { ...state, loading: true, error: '' };

    case GET_POOL_PACKETS_SUCCESS:
      let _pool = [];
      if (action.payload && action.payload.hasOwnProperty('nids')) {
        _pool = action.payload['nids'];
      }
      return { ...state, loading: false, pool: _pool, error: '' };

    case GET_POOL_PACKETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case SET_SEEN_PACKET:
      let stateSeen = { ...state.seen };

      if (action.payload.nid) {
        stateSeen = { ...stateSeen, [action.payload.nid]: true };
      } else {
        stateSeen = {};
      }
      setSeenPackets(stateSeen);

      return {
        ...state,
        seen: stateSeen,
      };

    case SET_ACTIVE_PACKET:
      return {
        ...state,
        activePacketID: action.payload.nid,
        relatedArticles: [],
      };

    case GET_RELATED_PACKET:
      return {
        ...state,
        relatedArticlesLoading: true,
        relatedArticlesError: '',
      };

    case GET_RELATED_PACKET_SUCCESS:
      const relatedArticles = {};

      action.payload.forEach((article) => {
        relatedArticles[article.nid] = article;
      });

      return {
        ...state,
        entries: {
          ...state.entries,
          ...relatedArticles,
        },
        relatedArticles: Object.keys(relatedArticles),
        relatedArticlesLoading: false,
        relatedArticlesError: '',
      };

    case GET_RELATED_PACKET_ERROR:
      return {
        ...state,
        relatedArticlesLoading: false,
        relatedArticlesError: action.payload.message,
      };

    default:
      return { ...state };
  }
};
