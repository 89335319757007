import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../components/common/CustomBootstrap';

const LayoutWidth = ({ children }) => {
  return (
    <>
      <Row className={'h-100 p-0 m-0'}>
        <Colxx xxs={{ size: 12, offset: 0 }}>
          <div className={'wrapper-content-layout-w d-flex h-100 flex-column'}>
            {children}
          </div>
        </Colxx>
      </Row>
    </>
  );
};

export default LayoutWidth;
