/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const CONFIRMATION_USER = 'CONFIRMATION_USER';
export const CONFIRMATION_USER_SUCCESS = 'CONFIRMATION_USER_SUCCESS';
export const CONFIRMATION_USER_ERROR = 'CONFIRMATION_USER_ERROR';

export const USR_MGMT = 'USR_MGMT';
export const USR_MGMT_SUCCESS = 'USR_MGMT_SUCCESS';
export const USR_MGMT_ERROR = 'USR_MGMT_ERROR';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_ERROR = 'FETCH_USER_DATA_ERROR';

export const LMS_REGISTER_USER = 'LMS_REGISTER_USER';
export const LMS_REGISTER_USER_SUCCESS = 'LMS_REGISTER_USER_SUCCESS';
export const LMS_REGISTER_USER_ERROR = 'LMS_REGISTER_USER_ERROR';

export const GET_LMS_USER = 'GET_LMS_USER';
export const GET_LMS_USER_SUCCESS = 'GET_LMS_USER_SUCCESS';
export const GET_LMS_USER_ERROR = 'GET_LMS_USER_ERROR';

export const GET_DETAILS_NODE = 'GET_DETAILS_NODE';
export const GET_DETAILS_NODE_SUCCESS = 'GET_DETAILS_NODE_SUCCESS';
export const GET_DETAILS_NODE_ERROR = 'GET_DETAILS_NODE_ERROR';

export const GET_PACKET = 'GET_PACKET';
export const GET_PACKET_SUCCESS = 'GET_PACKET_SUCCESS';
export const GET_PACKET_ERROR = 'GET_PACKET_ERROR';

export const GET_RELATED_PACKET = 'GET_RELATED_PACKET';
export const GET_RELATED_PACKET_SUCCESS = 'GET_RELATED_PACKET_SUCCESS';
export const GET_RELATED_PACKET_ERROR = 'GET_RELATED_PACKET_ERROR';

export const SET_SEEN_PACKET = 'SET_SEEN_PACKET';
export const SET_ACTIVE_PACKET = 'SET_ACTIVE_PACKET';

export const GET_POOL_PACKETS = 'GET_POOL_PACKETS';
export const GET_POOL_PACKETS_SUCCESS = 'GET_POOL_PACKETS_SUCCESS';
export const GET_POOL_PACKETS_ERROR = 'GET_POOL_PACKETS_ERROR';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const CHECK_SUBSCRIPTION_ID = 'CHECK_SUBSCRIPTION_ID';
export const CHECK_SUBSCRIPTION_ID_SUCCESS = 'CHECK_SUBSCRIPTION_ID_SUCCESS';
export const CHECK_SUBSCRIPTION_ID_ERROR = 'CHECK_SUBSCRIPTION_ID_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';
