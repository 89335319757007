import React, { useState } from 'react';
import { Row, Card, CardTitle, Label, FormGroup, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
// import { resetPassword } from '../../redux/actions';
import logo from "../../assets/imgs/logo.svg";
import {resetPasswordAsync} from "../../api";

const validateNewPassword = (values) => {
  const { newPassword, newPasswordAgain } = values;
  const errors = {};
  if (newPasswordAgain && newPassword !== newPasswordAgain) {
    errors.newPasswordAgain = 'Please check your new password';
  }

  if (!newPassword) {
    errors.newPasswordAgain = 'Please enter your password';
  } else if (newPassword.length < 6) {
    errors.newPasswordAgain = 'Value must be longer than 6 characters';
  }

  return errors;
};

const ResetPassword = ({
  location,
  history,
  loading,
  error,
  resetPasswordAction,
}) => {
  const [newPassword] = useState('');
  const [newPasswordAgain] = useState('');


  const onResetPassword = (values) => {
    if (!loading) {
      console.log('###');
      console.log(history);
      const params = new URLSearchParams(location.search);
      const oobCode = params.get('oobCode');
      if (oobCode) {
        if (values.newPassword !== '') {
          resetPasswordAction({
            newPassword: values.newPassword,
            resetPasswordCode: oobCode,
            history,
          });
        }
      } else {
        // NotificationManager.warning(
        //   'Please check your email url.',
        //   'Reset Password Error',
        //   3000,
        //   null,
        //   null,
        //   ''
        // );
      }
    }
  };

  const initialValues = { newPassword, newPasswordAgain };

  return (
    <Row className="h-100">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side ">
            <p className="text-white h2">
              <img src={logo} className="" alt="logo" width={200} />
            </p>

              <p className="white mb-0">
                Bitte verwenden Sie Ihre Anmeldeinformationen, um sich anzumelden.
                <br />
                Wenn Sie kein Kunde sind,

                <NavLink to="/user/register" className="white">
                  {' '}registrieren{' '}
                </NavLink>
                Sie sich bitte.
              </p>

          </div>
          <div className="form-side">
            <NavLink to="/" className="white">
              <span className="logo-single" />
            </NavLink>
            <CardTitle className="mb-4">
              <IntlMessages id="user.reset-password" />
            </CardTitle>

            <Formik
              validate={validateNewPassword}
              initialValues={initialValues}
              onSubmit={onResetPassword}
            >
              {({ errors, touched }) => (
                <Form className="av-tooltip tooltip-label-bottom">
                  <FormGroup className="form-group has-float-label">
                    <Label>
                      <IntlMessages id="user.new-password" />
                    </Label>
                    <Field
                      className="form-control"
                      name="newPassword"
                      type="password"
                    />
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label>
                      <IntlMessages id="user.new-password-again" />
                    </Label>
                    <Field
                      className="form-control"
                      name="newPasswordAgain"
                      type="password"
                    />
                    {errors.newPasswordAgain && touched.newPasswordAgain && (
                      <div className="invalid-feedback d-block">
                        {errors.newPasswordAgain}
                      </div>
                    )}
                  </FormGroup>

                  <div className="d-flex justify-content-between align-items-center">

                    <Button
                      color="primary"
                      className={`btn-shadow btn-multiple-state ${
                        loading ? 'show-spinner' : ''
                      }`}
                      size="lg"
                    >
                      <span className="spinner d-inline-block">
                        <span className="bounce1" />
                        <span className="bounce2" />
                        <span className="bounce3" />
                      </span>
                      <span className="label">
                        <IntlMessages id="user.reset-password-button" />
                      </span>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </Colxx>
    </Row>
  );
};

// const mapStateToProps = ({ authUser }) => {
//   const { newPassword, resetPasswordCode, loading, error } = authUser;
//   return { newPassword, resetPasswordCode, loading, error };
// };
//
// export default connect(mapStateToProps, {
//   resetPasswordAction: () => {},
// })(ResetPassword);



const mapStateToProps = ({ authUser }) => {
  const { newPassword, resetPasswordCode, loading, error } = authUser;
  return { newPassword, resetPasswordCode, loading, error };
};
const mapActionsToProps = (dispatch) => {
  return {
    resetPasswordAction: resetPasswordAsync(dispatch),
  };
};
export default connect(mapStateToProps, mapActionsToProps)(ResetPassword);
