import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { levelTypes } from 'constants/levels';
import PacketNavigator from 'components/common/packet-navigator/packet-navigator';
import Markup from 'components/markup/markup';

import icon1 from '../packet/imgs/icon1.svg';

import styles from './packet-header.module.scss';

const navigatorColorMap = {
  portrait: 'gold',
  landscape: 'blue',
  empty: 'tiffany',
};

const PacketHeader = ({
  type,
  title,
  imageUrl,
  imageAlt,
  intro,
  levelLabel,
  levelType,
  readingTime,
  exerciseTime,
}) => (
  <div
    className={classnames(styles.root, {
      [styles.imagePortrait]: type === 'portrait',
      [styles.imageLandscape]: type === 'landscape',
      [styles.imageEmpty]: type === 'empty',
    })}
  >
    {type !== 'empty' && (
      <div className={styles.image}>
        <img src={imageUrl} alt={imageAlt || title} />
      </div>
    )}
    <div className={styles.overlay}>
      <div className={styles.widget}>
        <img src={icon1} alt="" role="presentation" />
      </div>
      <div className={styles.content}>
        <div className={styles.categories}>
          <span className={styles.categoriesDecorator} />
          <span className={styles.categoriesText}>
            Main category
            <br />
            <strong>#Category</strong>
          </span>
          <span className={styles.categoriesDecorator} />
        </div>
        <h1 className={styles.title}>{title}</h1>
        {intro && <Markup className={styles.intro} content={intro} />}
      </div>
      <div className={styles.navigator}>
        <PacketNavigator
          levelLabel={levelLabel}
          levelType={levelType}
          readingTime={readingTime}
          exerciseTime={exerciseTime}
          theme={navigatorColorMap[type]}
        />
      </div>
    </div>
  </div>
);

PacketHeader.propTypes = {
  type: PropTypes.oneOf(['portrait', 'landscape', 'empty']).isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  intro: PropTypes.string,
  levelLabel: PropTypes.string,
  levelType: PropTypes.oneOf(levelTypes),
  readingTime: PropTypes.number,
  exerciseTime: PropTypes.number,
};

export default PacketHeader;
