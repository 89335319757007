let buildForBackend = 0;
let adminPath = '/console';
if (
  process.env.hasOwnProperty('REACT_APP_FOR_BACKEND') &&
  parseInt(process.env.REACT_APP_FOR_BACKEND)
) {
  buildForBackend = 1;
  adminPath = '/backend/console';
}
export const adminRoot = adminPath;
export const backendBuild = buildForBackend;
export const defaultLocale = 'de';

export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'de', name: 'Deutsch', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

export const SPRACHEN_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const SPRACHEN_USR = process.env.REACT_APP_API_USER;
export const SPRACHEN_PASSWD = process.env.REACT_APP_API_PASS;
