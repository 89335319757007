import React, { useContext } from 'react';
import LangContext from '../context/LangContext';
const InjectMassage = (props) => {
  const { messages } = useContext(LangContext);
  const { id } = props;

  return <>{messages[id]}</>;
};

export default InjectMassage;
