import React, { useEffect, useContext } from 'react';
import {
  Row,
  Card,
  CardTitle,
  Label,
  FormGroup,
  Button,
  Input,
  Form,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { loginWithEmailPassword } from '../../api';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import logo from '../../assets/imgs/logo.svg';
import LangContext from '../../context/LangContext';

const Login = ({ history, loading, error, loginUserAction }) => {
  const messages = useContext(LangContext);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(messages['str.is.not.email'])
      .required(messages['required']),
    password: Yup.string()
      .min(6, messages['passwd.min.6'])
      .required(messages['required']),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      loginUserAction(values, history);
    },
  });

  return (
    <Row className="h-100">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side ">
            <p className="text-white h2">
              <img
                src={logo}
                className=""
                alt="logo"
                width={200}
                height={'auto'}
              />
            </p>

            <p className="white mb-0">
              Bitte verwenden Sie Ihre Anmeldeinformationen, um sich anzumelden.
              <br />
              Wenn Sie kein Kunde sind,
              <NavLink to="/user/register" className="white"> registrieren{' '}
              </NavLink>
              Sie sich bitte.
            </p>
          </div>

          <div className="form-side">
            <NavLink to="/" className="white">
              <span className="logo-single" />
            </NavLink>
            <CardTitle className="mb-4">
              <IntlMessages id="user.login-title" />
            </CardTitle>
            <div>
              <h5>{error} </h5>
            </div>

            <Form onSubmit={formik.handleSubmit}>
              <FormGroup className="form-group has-float-label">
                <Label>
                  <IntlMessages id="user.email" />
                </Label>
                <Input
                  autoComplete="off"
                  type="text"
                  defaultValue={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.errors.email && formik.touched.email
                      ? 'text-input error'
                      : 'text-input'
                  }
                  name={'email'}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="input-feedback">{formik.errors.email}</div>
                )}
              </FormGroup>
              <FormGroup className="form-group has-float-label">
                <Label>
                  <IntlMessages id="user.password" />
                </Label>
                <Input
                  autoComplete="off"
                  type="password"
                  defaultValue={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.errors.password && formik.touched.password
                      ? 'text-input error'
                      : 'text-input'
                  }
                  name={'password'}
                />
                {formik.errors.password && formik.touched.password && (
                  <div className="input-feedback">{formik.errors.password}</div>
                )}
              </FormGroup>

              <div className="d-flex justify-content-between align-items-center">
                <Button
                  color="primary"
                  className={`btn-shadow btn-multiple-state ${
                    loading ? 'show-spinner' : ''
                  }`}
                  size="lg"
                  disabled={formik.isSubmitting}
                >
                  <span className="spinner d-inline-block">
                    <span className="bounce1" />
                    <span className="bounce2" />
                    <span className="bounce3" />
                  </span>
                  <span className="label">
                    <IntlMessages id="user.login-button" />
                  </span>
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </Colxx>
    </Row>
  );
};
const mapStateToProps = ({ authUser }) => {
  const { loading, error } = authUser;
  return { loading, error };
};
const mapActionsToProps = (dispatch) => {
  return {
    loginUserAction: loginWithEmailPassword(dispatch),
  };
};
export default connect(mapStateToProps, mapActionsToProps)(Login);
