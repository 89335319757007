import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CONFIRMATION_USER,
  CONFIRMATION_USER_SUCCESS,
  CONFIRMATION_USER_ERROR,
  USR_MGMT,
  USR_MGMT_SUCCESS,
  USR_MGMT_ERROR,
  FETCH_USER_DATA,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_ERROR,
  GET_PACKET_SUCCESS,
  GET_PACKET_ERROR,
  GET_RELATED_PACKET_SUCCESS,
  GET_RELATED_PACKET_ERROR,
  GET_POOL_PACKETS_SUCCESS,
  GET_POOL_PACKETS_ERROR,
  SET_SEEN_PACKET,
  SET_ACTIVE_PACKET,
  CHECK_SUBSCRIPTION_ID,
  CHECK_SUBSCRIPTION_ID_ERROR,
  CHECK_SUBSCRIPTION_ID_SUCCESS
} from './types';

export const confirmationUser = (uuid) => ({
  type: CONFIRMATION_USER,
  payload: uuid,
});
export const confirmationUserSuccess = (user) => ({
  type: CONFIRMATION_USER_SUCCESS,
  payload: user,
});
export const confirmationUserError = (message) => ({
  type: CONFIRMATION_USER_ERROR,
  payload: { message },
});

export const userMgmtAction = (mode, actionCode, history) => ({
  type: USR_MGMT,
  payload: { mode, actionCode, history },
});
export const userMgmtActionSuccess = (user) => ({
  type: USR_MGMT_SUCCESS,
  payload: user,
});
export const userMgmtActionError = (info) => ({
  type: USR_MGMT_ERROR,
  payload: info,
});
export const fetchUserData = (user, history) => ({
  type: FETCH_USER_DATA,
  payload: { user, history },
});
export const fetchUserDataSuccess = (user) => ({
  type: FETCH_USER_DATA_SUCCESS,
  payload: user,
});
export const fetchUserDataError = (message) => ({
  type: FETCH_USER_DATA_ERROR,
  payload: { message },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

// export const registerUser = (user, history) => ({
//   type: REGISTER_USER,
//   payload: { user, history },
// });
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

// ### Packet ####
export const getPacketSuccess = (user) => ({
  type: GET_PACKET_SUCCESS,
  payload: user,
});
export const getPacketError = (message) => ({
  type: GET_PACKET_ERROR,
  payload: { message },
});

export const getPoolPacketsSuccess = (user) => ({
  type: GET_POOL_PACKETS_SUCCESS,
  payload: user,
});
export const getPoolPacketsError = (message) => ({
  type: GET_POOL_PACKETS_ERROR,
  payload: { message },
});
export const setSeenPacket = (nid) => ({
  type: SET_SEEN_PACKET,
  payload: { nid },
});
export const setActivePacket = (nid) => ({
  type: SET_ACTIVE_PACKET,
  payload: { nid },
});
export const getRelatedPacketsSuccess = (ra) => ({
  type: GET_RELATED_PACKET_SUCCESS,
  payload: ra,
});
export const getRelatedPacketsError = (message) => ({
  type: GET_RELATED_PACKET_ERROR,
  payload: { message },
});

// ### END Packet ####

// export const checkSubscriptionId = (id) => ({
//   type: CHECK_SUBSCRIPTION_ID,
//   payload: id,
// });
export const checkSubscriptionSuccess = (user) => ({
  type: CHECK_SUBSCRIPTION_ID_SUCCESS,
  payload: user,
});
export const checkSubscriptionError = (message) => ({
  type: CHECK_SUBSCRIPTION_ID_ERROR,
  payload: { message },
});
