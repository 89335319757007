import { combineReducers } from 'redux';
import auth from './user';
import packet from './packets';
import subscriptions from './subscriptions';

export default combineReducers({
  authUser: auth,
  packet: packet,
  subscriptions: subscriptions,
});
