import { useSelector } from 'react-redux';

const useCurrentPacket = () => {
  return useSelector((state) => {
    const {
      packet: { entries, activePacketID },
    } = state;

    if (entries[activePacketID]) {
      return entries[activePacketID];
    }

    return null;
  });
};

export default useCurrentPacket;
