import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { Row } from 'reactstrap';
import { Colxx } from 'components/common/CustomBootstrap';

const ParallaxedImage = ({ imageUrl, orientation }) => {
  return (
    <Row className={`parallax-wrapper-row orientation-${orientation}`}>
      <Colxx xxs={12}>
        <Parallax y={['-75%', 0]} tagOuter="figure" className={'parallax-row'}>
          <img src={imageUrl} alt="" />
        </Parallax>
      </Colxx>
    </Row>
  );
};

export default ParallaxedImage;
