import React from 'react';
import SimpleModal from 'components/common/simple-modal/simple-modal';

import like from './icons/like.svg';

import styles from './social-media.module.scss';

const SocialMedia = () => (
  <div className={styles.root}>
    <div className={styles.title}>Did you like this article?</div>
    <SimpleModal
      ToggleComponent={() => (
        <button className={styles.action}>
          <img src={like} alt="Exercises" width={20} />
        </button>
      )}
    />
  </div>
);

export default SocialMedia;
