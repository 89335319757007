import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { getLevel } from 'helpers/level-utils';
import Loader from 'components/common/loader/loader';
import ArticleCard from 'components/common/article-card/article-card';

import styles from './related-articles.module.scss';

const sliderSettings = {
  spaceBetween: 0,
  watchOverflow: true,
  slidesPerView: 1.2,
  threshold: 10,
  breakpoints: {
    768: {
      slidesPerView: 2.2,
    },
    992: {
      slidesPerView: 3,
    },
  },
};

const RelatedArticles = ({ articles, loading }) => {
  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <Container fluid="lg">
          <div className={styles.title}>Related articles</div>
        </Container>
      </div>
      <div className={styles.articles}>
        {loading ? (
          <Loader position="absolute" />
        ) : (
          <Container fluid="lg">
            <Row>
              <Col xs={12} className="p-0">
                <Swiper {...sliderSettings} className={styles.slider}>
                  {articles.map((article) => {
                    const level = getLevel(article.level.id);

                    return (
                      <SwiperSlide key={article.nid} className={styles.slide}>
                        <ArticleCard
                          title={article.title}
                          url={`/console/paket/${article.nid}`}
                          imageUrl={article.mainImage.url}
                          levelLabel={level.label}
                          levelType={level.type}
                          readingTime={3}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </div>
  );
};

export default RelatedArticles;
