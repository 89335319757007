import React, { useEffect } from 'react';
import TopNav from 'components/top-nav/top-nav';

const PacketLayout = ({ containerClassnames, children, history }) => {
  useEffect(() => {
    document.body.classList.add('packet-page');

    return () => {
      document.body.classList.remove('packet-page');
    };
  }, []);

  return (
    <div id="app-container" className={containerClassnames}>
      <TopNav history={history} />
      <main>
        <div className="container-fluid p-0">{children}</div>
      </main>
    </div>
  );
};

export default PacketLayout;
