import React, { useEffect, useState } from 'react';

const ScrollPageIndicator = () => {
  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = `${(scrollPx / winHeightPx) * 100}%`;

      setScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  const progressContainerStyle = {
    background: '#545762',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
    height: '5px',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: 99,
  };

  const progressBarStyle = {
    height: '5px',
    background: '#F0C509',
    width: scrolled,
  };

  return (
    <>
      <div
        className="progress-container z-index-2"
        style={progressContainerStyle}
      >
        <div className="progress-bar" style={progressBarStyle} />
      </div>
    </>
  );
};
export default ScrollPageIndicator;
