import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { adminRoot, backendBuild } from './constants/defaultValues';
import UserLayout from './layout/UserLayout';
import Login from './views/user/login';
import Register from './views/user/register';
import ViewThankYou from './views/user/register-confirmation';
import ResetPassword from './views/user/reset-password';

import { LangProvider } from './context/LangContext';
import AppLocale from './lang';
import { getPoolPackets } from './api';
import { ParallaxProvider } from 'react-scroll-parallax';

import Home from './views/home';
import Admin from './admin';
import RegistrationLayout from "./layout/RegistrationLayout";

const AuthRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser || backendBuild ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class App extends React.Component {
  componentDidMount() {
    this.props.getPoolPacketsAction();
  }

  render() {
    const { locale, loginUser } = this.props;
    const currentAppLocale = AppLocale[locale];

    return (
      <ParallaxProvider>
        <div className="h-100">
          <LangProvider value={currentAppLocale}>
            <Router>
              <Switch>
                <Route path="/" exact render={(props) => <Home {...props} />} />
                <AuthRoute
                  path={adminRoot}
                  authUser={loginUser}
                  component={Admin}
                />
                <Route
                  path="/user"
                  render={(props) => (
                    <UserLayout>
                      <Switch>
                        <Redirect
                          exact
                          from={`${props.match.url}/`}
                          to={`${props.match.url}/login`}
                        />
                        <Route
                          path={`${props.match.url}/login`}
                          render={(props) => <Login {...props} />}
                        />
                        <Route
                          path={`${props.match.url}/register`}
                          render={(props) => <Register {...props} />}
                        />
                        {/*<Route*/}
                        {/*  exact*/}
                        {/*  path={`${props.match.url}/passwort-vergessen/bestaetigen`}*/}
                        {/*  render={(props) => <ForgotPasswordConfirmation {...props} />}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*  path={`${props.match.url}/passwort-vergessen`}*/}
                        {/*  render={(props) => <ForgotPassword {...props} />}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*  exact*/}
                        {/*  path={`${props.match.url}/passwort-reset/bestaetigen`}*/}
                        {/*  render={(props) => <ResetPasswordConfirmation {...props} />}*/}
                        {/*/>*/}
                        <Route
                          path={`${props.match.url}/passwort-reset`}
                          render={(props) => <ResetPassword {...props} />}
                        />
                        <Redirect to="/error" />
                      </Switch>
                    </UserLayout>
                  )}
                />
                <Route
                  path="/registrierung/danke"
                  exact
                  render={(props) => <RegistrationLayout>
                    <ViewThankYou {...props} />
                  </RegistrationLayout>}
                />
              </Switch>
            </Router>
          </LangProvider>
        </div>
      </ParallaxProvider>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user: loginUser } = authUser;

  const locale = 'de';
  return { loginUser, locale };
};
const mapActionsToProps = (dispatch) => {
  return {
    getPoolPacketsAction: getPoolPackets(dispatch),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(App);
