import React from 'react';
import { Row } from 'reactstrap';
import { Colxx } from 'components/common/CustomBootstrap';
import LayoutWidth from 'layout/LayoutWidth';
import SocialMedia from 'components/common/social-media/social-media';
import neutral from 'components/common/packet/imgs/neutral.svg';
import SimpleModal from 'components/common/simple-modal/simple-modal';
import Markup from 'components/markup/markup';
import contact from './Contact.svg';

const PacketAuthor = ({ data }) => {
  const {
    img: urlImg = '',
    name = '',
    biography = '',
    contactLink = '',
  } = data;

  return (
    <>
      {data && data.hasOwnProperty('id') && data['id'] ? (
        <Row className="packet-author-row w-100  m-0 p-0">
          <Colxx xxs={{ size: 12 }} className="m-0 p-0">
            <LayoutWidth>
              <Row className={''}>
                <Colxx xxs={{ size: 12 }}>
                  <div
                    className={
                      'd-block d-sm-none pt-3 pb-5 text-center justify-content-center align-items-center'
                    }
                  >
                    <SocialMedia />
                  </div>
                </Colxx>
              </Row>
              <Row className={''}>
                <Colxx
                  xxs={{ size: 12, align: 'center' }}
                  xs={6}
                  sm={5}
                  md={4}
                  lg={5}
                  xl={4}
                  className="wrapper-author-data "
                >
                  <div className="pb-4 wrapper-image">
                    {urlImg ? (
                      <img src={urlImg} className="imgClass" alt="" />
                    ) : (
                      <div className="neutral-wrapper">
                        <img
                          src={neutral}
                          width="100px"
                          className="neutral"
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                  <div className="name">{name}</div>
                  <div className="biography">
                    <Markup content={biography} as="span" />
                  </div>
                  <div className="contact">
                    <SimpleModal
                      ToggleComponent={() => (
                        <a
                          href={contactLink}
                          onClick={(evt) => evt.preventDefault()}
                          target={'_blank'}
                          rel="noopener noreferrer"
                        >
                          <img src={contact} alt="" width={20} />
                          <span className="pl-2">Contact author</span>
                        </a>
                      )}
                    />
                  </div>
                </Colxx>
                <Colxx
                  xxs={{ size: 12, align: 'center' }}
                  xs={6}
                  sm={7}
                  md={8}
                  lg={7}
                  xl={8}
                  className="wrapper-author-social d-flex flex-column justify-content-between pl-4"
                >
                  <div className="pt-4 d-none d-sm-block">
                    <SocialMedia />
                  </div>
                </Colxx>
              </Row>
            </LayoutWidth>
          </Colxx>
        </Row>
      ) : null}
    </>
  );
};

export default PacketAuthor;
