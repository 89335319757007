import React from 'react';
import PropTypes from 'prop-types';

import styles from './packet-top.module.scss';

const PacketTop = ({ authorName, createdDate }) => {
  if (!(authorName || createdDate)) {
    return null;
  }

  return (
    <div className={styles.root}>
      {authorName && (
        <div className={styles.author}>
          by <span className={styles.authorName}>{authorName}</span>
        </div>
      )}
      {createdDate}
    </div>
  );
};

PacketTop.propTypes = {
  authorName: PropTypes.string,
  createdDate: PropTypes.string,
};

export default PacketTop;
