import React, {useEffect, useState} from 'react';
import {
  Row,
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import {connect, shallowEqual, useSelector} from 'react-redux';
import {checkSubscriptionIDAsync, registerUser} from '../../api';


import IntlMessages from '../../helpers/IntlMessages';
import { Colxx } from '../../components/common/CustomBootstrap';

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { CSSTransition } from 'react-transition-group';
import logo from '../../assets/imgs/logo.svg';
import {
  getEntrySubscriptionState,
  getLoadingSubscriptionState,
  getStatusMessageSubscriptionState
} from "../../selectors/subscriptions";

import {
  getErrorAuthState
} from "../../selectors/auth";

import queryString from "query-string";
import {updateUrl} from '../../helpers/Utils';
import {loginWithEmailPassword} from "../../api";

const Register = ({
  errorSubscription,
  history,
  checkSubscriptionIdAction,
  registerUserAction,
  location,
  loading,
  error,
  loadingSubscriptions,
  entry,
  statusMessage
}) => {

  const [state, setState] = useState({
    aboId: '',
    name: '',
    familyName: '',
    email: '',
    password: '',
    confirmPassword: '',
    inProp: false,
    init: false,
    reset: false,
    notification: '',
    disabled: false
  })

  const validationSchema = Yup.object().shape({
    aboId: Yup.string()
      .required("Required"),
    email: Yup.string()
      .email()
      .required("Required"),
    name: Yup.string()
      .required("Required"),
    familyName: Yup.string()
      .required("Required"),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required("Required")
  })

  const formik = useFormik({
    initialValues: {
      aboId: state.aboId,
      email: state.email,
      name: state.name,
      familyName: state.familyName,
      password: state.password
    },

    validationSchema: validationSchema,
    onSubmit: async values => {
      registerUserAction({...values, uuid: entry.uuid}, history)
    },
  });

  let parsed = {};
  if (location && location.hasOwnProperty('search')) {
    parsed = queryString.parse(location.search);
    parsed = Object.assign({}, parsed);
  }
  const {pathname} = location;

  useEffect(() => {
    if (entry && entry.hasOwnProperty('uuid')) {

      updateUrl(pathname, entry, parsed, history);

      if (!state.inProp ) {
        setState({...state, inProp: true,  init: true, disabled: true})
        formik.setFieldValue('aboId', entry['AboId']);
        formik.setFieldValue('name', entry['Vorname']);
        formik.setFieldValue('familyName', entry['Nachname']);
      }
    }
    else {
      updateUrl(pathname, entry, parsed, history);

      if (!state.init) {
        if (parsed.hasOwnProperty('aboId')) {
          checkSubscriptionIdAction({
            aboId: parsed["aboId"].trim()
          });
          setState({...state, init: true, aboId: parsed["aboId"]})
        }
        // history.push('/user/register');
      }
    }
  }, [entry, state.inProp, state.init, state, parsed, history])

  const onUserRegister = () => {
    // if (email !== '' && password !== '') {
    //   history.push('/');
    // }
    // call registerUserAction()
  };

  const handleChange = (e) => {
    console.log(e)
  }


  const checkAboId = () => {
    if (!loading) {
      checkSubscriptionIdAction({
        aboId: state.aboId.trim()
      })
    }
  }

  // formik.setFieldValue('name', "fdfs");

  return (
    <Row className="h-100">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side ">
            <p className="text-white h2">
              <img src={logo} className="" alt="logo" width={200} />
            </p>
            <p className="white mb-0">
              Wenn Sie bereits registriert sind,  <br />
              <NavLink to="/user/login" className="white">
                melden Sie sich bitte an
              </NavLink>
              .
            </p>
          </div>
          <div className="form-side">
            <NavLink to="/" className="white">
              <span className="logo-single" />
            </NavLink>
            <CardTitle className="mb-4">
              <IntlMessages id="user.register" />
            </CardTitle>
            <div>
              {/*<h5><IntlMessages id={statusMessage} /></h5>*/}
              {/*{entry && entry.hasOwnProperty('confirmed') && entry['confirmed'] ? (*/}
              {/*  <h5>Abo ID wurde bestätigt</h5>*/}
              {/*): null}*/}
              <h5>{errorSubscription}</h5>

            </div>

            <div>
              <form onSubmit={formik.handleSubmit}>

                <FormGroup className="form-group has-float-label mb-4">
                  <Label for={'aboId'}>
                    <IntlMessages id="subscription.id" />
                    {/*{loading && <div className="loading-relative" />}*/}
                  </Label>
                  <Input
                    disabled={state.disabled}
                    type="text"
                    defaultValue={formik.values.aboId}
                    onChange={(e) => {
                      const {name,value} = e.target;
                      setState({...state, [name]: value})
                      formik.handleChange(e);
                    }}
                    name={'aboId'}
                    onBlur={formik.handleBlur}
                    className={
                      formik.errors.aboId && formik.touched.aboId
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  {formik.errors.aboId && formik.touched.aboId && (
                    <div className="input-feedback">{formik.errors.aboId}</div>
                  )}
                </FormGroup>

                <CSSTransition
                  in={state.inProp}
                  timeout={200}
                  classNames="registrationMailPass"
                  unmountOnExit
                >
                  <div >
                    <FormGroup className="form-group has-float-label  mb-4">
                      <Label>
                        <IntlMessages id="user.name" />
                      </Label>

                      <Input
                        autoComplete="off"
                        type="text"
                        defaultValue={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.name && formik.touched.name
                            ? "text-input error"
                            : "text-input"
                        }
                        name={'name'}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <div className="input-feedback">{formik.errors.name}</div>
                      )}

                    </FormGroup>

                    <FormGroup className="form-group has-float-label  mb-4">
                      <Label>
                        <IntlMessages id="user.familyName" />
                      </Label>

                      <Input
                        autoComplete="off"
                        type="text"
                        defaultValue={formik.values.familyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.familyName && formik.touched.familyName
                            ? "text-input error"
                            : "text-input"
                        }
                        name={'familyName'}
                      />
                      {formik.errors.familyName && formik.touched.familyName && (
                        <div className="input-feedback">{formik.errors.familyName}</div>
                      )}

                    </FormGroup>


                    <FormGroup className="form-group has-float-label  mb-4">
                      <Label>
                        <IntlMessages id="user.email" />
                      </Label>
                      <Input
                        autoComplete="off"
                        type="email"
                        defaultValue={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.email && formik.touched.email
                            ? "text-input error"
                            : "text-input"
                        }
                        name={'email'}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <div className="input-feedback">{formik.errors.email}</div>
                      )}
                      {error}

                    </FormGroup>

                    <FormGroup className="form-group has-float-label  mb-4">
                      <Label>
                        <IntlMessages id="user.password" />
                      </Label>
                      <Input
                        autoComplete="off"
                        type="password"
                        defaultValue={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.password && formik.touched.password
                            ? "text-input error"
                            : "text-input"
                        }
                        name={'password'}
                      />
                      {formik.errors.password && formik.touched.password && (
                        <div className="input-feedback">{formik.errors.password}</div>
                      )}

                    </FormGroup>
                  </div>

                </CSSTransition>

                {!state.inProp ? null : ( <div className="d-flex justify-content-end align-items-center">
                  <Button
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                    onClick={() => formik.handleSubmit()}
                    disabled={formik.isSubmitting}
                  >
                    <IntlMessages id="user.register.label" />
                  </Button>
                </div>) }

              </form>
              {state.inProp ? null : (<div className="d-flex justify-content-end align-items-center">
                <Button
                  disabled={state.aboId.trim().length ? false : true}
                  color="primary"
                  className="btn-shadow"
                  size="lg"
                  onClick={checkAboId}
                >
                  <IntlMessages id="subscription.check.label" />
                </Button>
              </div>) }

            </div>

          </div>
        </Card>
      </Colxx>
    </Row>
  );
};

const mapStateToProps = ({ authUser, subscriptions }) => {
  const {loading : loadingSubscriptions, entry = {}, statusMessage, error: errorSubscription} = subscriptions;
  const { loading, error} = authUser;

  return { loading, error, loadingSubscriptions, entry, statusMessage, errorSubscription };
};
const mapActionsToProps = (dispatch) => {
  return {
    registerUserAction: registerUser(dispatch),
    checkSubscriptionIdAction: checkSubscriptionIDAsync(dispatch),

    // loginUserAction: loginWithEmailPassword(dispatch),
  };
};
export default connect(mapStateToProps, mapActionsToProps)(Register);
