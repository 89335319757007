import React from 'react';

const MAIN_SLOT_ID = 130;
const EXERCISES_SLOT_ID = 131;

const SlotHeader = ({ slotName, slotId }) => {
  const hideOnSlots = [MAIN_SLOT_ID, EXERCISES_SLOT_ID];

  if (hideOnSlots.includes(parseInt(slotId, 10))) {
    return null;
  }

  return (
    <div className="text-center">
      <h2>{slotName}</h2>
    </div>
  );
};

export { SlotHeader };
