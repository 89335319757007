/*
Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
05.Coming Soon Popup
*/

module.exports = {
  'general.copyright': 'Disney plus © 2020 Alle Rechte vorbehalten.',

  'subscription.id': 'Abonnement ID',
  'valid.subscription.id': '',
  'not.valid.subscription.id': 'ungültiges ID-Abonnement',
  'subscription.check.label': 'Abonnement prüfen',
  'user.login-title': 'Anmelden',
  'user.register.label': 'Benutzer registrieren',
  'user.register': 'Benutzer registrieren',
  'user.forgot-password': 'Passwort vergessen',
  'user.email': 'E-mail',
  'user.password': 'Passwort',
  'user.forgot-password-question': 'Passwort vergessen?',
  'user.familyName': 'Nachname',
  'user.name': 'Vorname',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'RESET',

  'user.reset-password': 'Passwort zurücksetzen',
  'user.new-password-again': 'Neues Passwort bestätigen',
  'user.new-password': 'Neues Kennwort',
  'user.username': 'Username',
  'user.registration.thankyou.title': 'Danke für Ihre Registrierung',
  'user.registration.confirmation.title': 'Bestätigung Registration',
  'user.password.forgot.confirmation.title': 'Kennwort erfolgreich geändert',
  'user.password.forgot.confirmation.text':
    'Bitte überprüfen Sie Ihre E-Mails.',

  /* 03.Menu */
  'menu.home': 'Home',
  'menu.app': 'Home',
  'menu.console': 'Console',
  'menu.start': 'Drive',

  required: 'Pflichtfeld',
  'str.is.not.email': 'E-Mail muss eine gültige E-Mail sein',
  'passwd.min.6': 'Passwort muss mindestens 6 Zeichen lang sein',

  /* 04.Error Page */
  'pages.error-title': 'Ups ... sieht aus wie ein Fehler aufgetreten ist!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',

  /* 05.Coming Soon Popup */
  'popup.title': 'Coming soon!',
  'popup.promise': 'We are working hard to show you this cool feature soon.',
  'popup.ok-button.text': "That's fine",
  'popup.image.alt-text': '3 geometric figures with a flag',
};
