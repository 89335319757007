import React from 'react';
import LayoutWidth from 'layout/LayoutWidth';
import PacketTop from 'components/common/author/packet-top/packet-top';
import SocialMedia from 'components/common/social-media/social-media';
import ArticleBody from './article-body/article-body';

const SimpleArticle = ({ data }) => {
  const { author = { id: 0 } } = data;
  return (
    <>
      <LayoutWidth>
        {data && data.author && (
          <PacketTop authorName={data.author.name} createdDate="08/08/2020" />
        )}
      </LayoutWidth>
      <ArticleBody body={data.body} />
      <LayoutWidth>{author.id ? null : <SocialMedia />}</LayoutWidth>
    </>
  );
};

export default SimpleArticle;
