import React from 'react';
import Sticky from 'react-stickynode';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import SimpleModal from 'components/common/simple-modal/simple-modal';

const profileUrl = '/assets/img/me.jpg';

const TopNav = () => (
  <Sticky enabled={true} top={0} innerZ={999}>
    <nav className="navbar packet-nav p-0 m-0 z-index-8">
      <div className="overlay-navbar" />
      <div className="container-fluid z-index-2">
        <div className="d-flex align-items-center navbar-left z-index-2">
          <img alt="Logo" src="/assets/img/logo-zeit.svg" height="16px" />
        </div>
        <div className="navbar-right z-index-2 flex-row d-flex justify-content-end align-items-center">
          <div className="h-100 mr-4">
            <SimpleModal
              ToggleComponent={() => (
                <a href="#search" onClick={(e) => e.preventDefault()}>
                  <img
                    alt="Search"
                    src="/assets/img/search.svg"
                    height="17px"
                  />
                </a>
              )}
            />
          </div>
          <UncontrolledDropdown className="dropdown-menu-right">
            <DropdownToggle className="p-0" color="empty">
              <div
                className="user-picture"
                style={{ backgroundImage: `url(${profileUrl})` }}
              />
            </DropdownToggle>
            <DropdownMenu className="mt-3" right>
              <DropdownItem onClick={() => {}}>Abmelden</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </nav>
  </Sticky>
);

export default TopNav;
