import { createSelector } from 'reselect';

const getLoadingAuth = (state) => {
  return state['authUser'].loading;
}; // reselect function
export const getLoadingAuthState = createSelector(
  [ getLoadingAuth ],
  (ap) => {
    return ap;
  }
)

const getErrorAuth = (state) => {
  return state['authUser'].error;
}; // reselect function
export const getErrorAuthState = createSelector(
  [ getErrorAuth ],
  (ap) => {
    return ap;
  }
)

const getErrorConfirmationAuth = (state) => {
  return state['authUser'].errorConfirmation;
}; // reselect function
export const getErrorConfirmationAuthState = createSelector(
  [ getErrorConfirmationAuth ],
  (ap) => {
    return ap;
  }
)

const getUser = (state) => {
  return state['authUser'].user;
}; // reselect function
export const getUserState = createSelector(
  [ getUser ],
  (ap) => {
    return ap;
  }
)

const getUserObject = (state) => {
  return state['authUser'].object;
}; // reselect function
export const getUserObjectState = createSelector(
  [ getUserObject ],
  (ap) => {
    return ap;
  }
)

const getUserConfirmed = (state) => {
  return state['authUser'].confirmed;
}; // reselect function
export const getUserConfirmedState = createSelector(
  [ getUserConfirmed ],
  (ap) => {
    return ap;
  }
)
