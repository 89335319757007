import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './loader.module.scss';

const Loader = ({ position = 'fixed' }) => (
  <div
    className={classnames(styles.root, {
      [styles.fixed]: position === 'fixed',
      [styles.absolute]: position === 'absolute',
    })}
  />
);

Loader.propTypes = {
  position: PropTypes.oneOf(['fixed', 'absolute']),
};

export default Loader;
