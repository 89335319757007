import { createSelector } from 'reselect';

const getLoadingSubscription = (state) => {
  return state['subscriptions'].loading;
}; // reselect function
export const getLoadingSubscriptionState = createSelector(
  [ getLoadingSubscription ],
  (ap) => {
    return ap;
  }
)

const getEntrySubscription = (state) => {
  return state['subscriptions'].entry;
}; // reselect function
export const getEntrySubscriptionState = createSelector(
  [ getEntrySubscription ],
  (ap) => {
    return ap;
  }
)

const getStatusMessageSubscription = (state) => {
  return state['subscriptions'].statusMessage;
}; // reselect function
export const getStatusMessageSubscriptionState = createSelector(
  [ getStatusMessageSubscription ],
  (ap) => {
    return ap;
  }
)
