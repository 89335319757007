import { axiosSprachenInstance } from './helpers';
import {
  getPoolPacketsSuccess,
  getPacketSuccess,
  getRelatedPacketsSuccess,
  loginUserSuccess,
  loginUserError,
  checkSubscriptionError,
  checkSubscriptionSuccess,
  registerUserError
} from '../actions';
import {
  GET_POOL_PACKETS,
  GET_PACKET,
  GET_RELATED_PACKET,
  LOGIN_USER, CHECK_SUBSCRIPTION_ID,
  CHECK_SUBSCRIPTION_ID_ERROR, REGISTER_USER,
} from '../actions/types';
import { auth, firestore } from '../helpers/Firebase';
import { setCurrentUser, setCurrentUserObject } from '../helpers/Utils';
import { adminRoot } from '../constants/defaultValues';

export const getPoolPackets = (dispatch) => () => {
  dispatch({ type: GET_POOL_PACKETS });
  // let response = require('./data/availableNids.json');
  // dispatch(getPoolPacketsSuccess(response));

  axiosSprachenInstance()
    .get(`/api/dds/ready/packages/0?_format=json`)
    .then((resp) => {
      // console.log(resp.data)
      dispatch(getPoolPacketsSuccess(resp.data));
    })
    .catch((error) => console.warn([error]));
};

export const getPacket = (dispatch) => (nid) => {
  dispatch({ type: GET_PACKET });

  axiosSprachenInstance()
    .get(`/api/v2/dp/data/structure/packet/${nid}?_format=json`)
    .then((resp) => {
      dispatch(getPacketSuccess(resp.data));
    })
    .catch((error) => console.warn([error]));
};

export const getRelatedPackets = (dispatch) => ({ nids }) => {
  dispatch({ type: GET_RELATED_PACKET });

  axiosSprachenInstance()
    .get(`/api/dds/group/packets/${nids}?_format=json`)
    .then((resp) => {
      dispatch(getRelatedPacketsSuccess(resp.data));
    })
    .catch((error) => console.warn([error]));
};

export const loginWithEmailPassword = (dispatch) => (values, history) => {
  dispatch({ type: LOGIN_USER });

  auth
    .signInWithEmailAndPassword(values.email, values.password)
    .then((authUser) => {
      let userInfo = {
        displayName: authUser.user.displayName,
        email: authUser.user.email,
        // emailVerified: loginUser.user.emailVerified,
        emailVerified: true,
        photoURL: authUser.user.photoURL,
        isAnonymous: authUser.user.isAnonymous,
        uid: authUser.user.uid,
        providerData: authUser.user.providerData,
      };

      if (!userInfo.emailVerified) {
        dispatch(loginUserError('Dieses Konto ist noch nicht verifiziert'));
      } else {
        setCurrentUserObject(userInfo);
        setCurrentUser(authUser.user);
        dispatch(loginUserSuccess(authUser.user));
        history.push(adminRoot);
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(loginUserError('Dieses Konto ist noch nicht verifiziert'));
    });
};



export const checkSubscriptionIDAsync = (dispatch) => (payload) => {
  dispatch({ type: CHECK_SUBSCRIPTION_ID });

  const { aboId } = payload;
  firestore.collection("users").where("AboId", "==", aboId)
    .get()
    .then(function(querySnapshot) {

      let response = {};
      querySnapshot.forEach(function(doc) {
        response = {
          uuid: doc.id,
          ...doc.data()
        };
      });


      if (response && !response.hasOwnProperty('uuid')) {
        dispatch(checkSubscriptionError('Abo ID does not exist!'));
      }
      else if (response && response.hasOwnProperty('confirmed') && response.confirmed) {
        dispatch(checkSubscriptionError('Abo ID have been confirmed!'));
      }
      else {
        dispatch(checkSubscriptionSuccess(response));
      }
    })
    .catch(function(error) {
      dispatch(checkSubscriptionError('An error occurred when checking the subscription!'));
      return {};
    });
}

const send_verification = () => {
  let user = auth.currentUser;
  user.sendEmailVerification().then(function() {
    // Email sent.
    // console.log('sent')
  }).catch(function(error) {
    // An error happened.
    // console.log('err', error)
  });
}

const registerUpdateUserInfoAsync = async (uuid, data) => {
  firestore.collection("users").doc(uuid).set(data, { merge: true });
}
export const registerUser = (dispatch) => (payload, history) => {
  dispatch({ type: REGISTER_USER });

  const { email, password, aboId, familyName, name, uuid } = payload;

  auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => {

      if (!authUser.message) {
        console.log(authUser.user)
        setCurrentUser(authUser.user);
        send_verification();

        registerUpdateUserInfoAsync(
          uuid,
          {
          "Mail": email,
          "Nachname": familyName,
          "Vorname": name,
          "confirmed": true,
          "userId": authUser.user.uid
          }
        );


        history.push("/registrierung/danke");

      } else {
        dispatch(registerUserError(authUser.message));
      }
    })
    .catch((error) => {
      dispatch(registerUserError(error.message));
    });


}

export const resetPasswordAsync = (dispatch) => (payload) => {

  const { resetPasswordCode, newPassword, history } = payload
  console.log("xx1x")
  console.log(resetPasswordCode)
  console.log(newPassword)
  console.log(history)
  console.log("xxx")
  auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => {
      console.log(user)
    })
    .catch((error) => error);
}
