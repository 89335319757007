/*
Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
05.Coming Soon Popup
*/

module.exports = {
  /* 01.General */
  'general.copyright': 'Disney plus © 2020 Todos los derechos reservados.',

  /* 02.User Login, Logout, Register */
  'subscription.id': 'Abonnement ID',
  'valid.subscription.id': '',
  'not.valid.subscription.id': 'ungültiges ID-Abonnement',
  'subscription.check.label': 'Abonnement prüfen',
  'user.login-title': 'Login',
  'user.register.label': 'Benutzer registrieren',
  'user.register': 'Benutzer registrieren',
  'user.forgot-password': 'Forgot Password',
  'user.email': 'E-mail',
  'user.password': 'Passwort',
  'user.forgot-password-question': 'Forget password?',
  'user.familyName': 'Nachname',
  'user.name': 'Vorname',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'RESET',
  'user.buy': 'BUY',
  'user.username': 'Username',

  /* 03.Menu */
  'menu.home': 'Home',
  'menu.app': 'Home',

  required: 'Pflichtfeld',
  'str.is.not.email': 'E-Mail muss eine gültige E-Mail sein',
  'passwd.min.6': 'Passwort muss mindestens 6 Zeichen lang sein',

  /* 04.Error  */
  'layouts.error-title': 'Vaya, parece que ha ocurrido un error!',
  'layouts.error-code': 'Código de error',
  'layouts.go-back-home': 'REGRESAR A INICIO',

  /* 05.Coming Soon Popup */
  'popup.title': 'Coming soon!',
  'popup.promise': 'We are working hard to show you this cool feature soon.',
  'popup.ok-button.text': "That's fine",
  'popup.image.alt-text': '3 geometric figures with a flag',
};
