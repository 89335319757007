import { useSelector } from 'react-redux';

const useCurrentRelatedArticles = () => {
  return useSelector((state) => {
    const {
      packet: {
        entries,
        relatedArticles: relatedNids = [],
        relatedArticlesLoading,
        relatedArticlesError,
      },
    } = state;

    const relatedArticles = relatedNids
      .map((nid) => {
        if (entries[nid]) {
          return entries[nid];
        }
        return null;
      })
      .filter((article) => !!article);

    return {
      entries: relatedArticles,
      loading: relatedArticlesLoading,
      error: relatedArticlesError,
    };
  });
};

export default useCurrentRelatedArticles;
