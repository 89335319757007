import {
  levelIdTypeMap,
  levelTypeLabelMap,
  LEVEL_FALLBACK_TYPE,
} from 'constants/levels';

export const getLevelType = (id) =>
  levelIdTypeMap[id] ? levelIdTypeMap[id] : LEVEL_FALLBACK_TYPE;

export const getLevel = (id) => {
  const type = getLevelType(id);

  return {
    type,
    label: levelTypeLabelMap[type],
  };
};
