export const LEVEL_TYPE_EASY = 'easy';
export const LEVEL_TYPE_MEDIUM = 'medium';
export const LEVEL_TYPE_ADVANCED = 'advanced';

export const LEVEL_FALLBACK_TYPE = LEVEL_TYPE_MEDIUM;

export const levelTypes = [
  LEVEL_TYPE_EASY,
  LEVEL_TYPE_MEDIUM,
  LEVEL_TYPE_ADVANCED,
];

export const levelIdTypeMap = {
  6: LEVEL_TYPE_ADVANCED,
  4: LEVEL_TYPE_EASY,
  0: LEVEL_TYPE_EASY,
  5: LEVEL_TYPE_MEDIUM,
};

export const levelTypeLabelMap = {
  [LEVEL_TYPE_EASY]: 'Easy',
  [LEVEL_TYPE_MEDIUM]: 'Medium',
  [LEVEL_TYPE_ADVANCED]: 'Advanced',
};
