import React from 'react';
import { Card, CardTitle, Row} from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx } from '../../components/common/CustomBootstrap';
import logo from "../../assets/imgs/logo.svg";
import {NavLink} from "react-router-dom";

const ThankYou = ({ match }) => {

  return <>
    <Row className="h-100">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side ">
            <p className="text-white h2">
              <img src={logo} className="" alt="logo" width={200} />
            </p>
          </div>

          <div className="form-side">
            <NavLink to="/" className="white">
              <span className="logo-single" />
            </NavLink>
            <CardTitle className="mb-4">
              <IntlMessages id="user.registration.thankyou.title" />
            </CardTitle>

            <p>
              Ihre Daten wurden erfolgreich gespeichert und es wurde eine E-Mail an die angegebene Adresse versandt.
            </p>
            <p>
              Bitte nutzen Sie den in der E-Mail enthalten Aktivierungslink, um Ihre Registrierung abzuschließen und Ihr Benutzerkonto zu aktivieren.
            </p>

            Mit freundlichen Grüßen <br />
            Ihr Spotlight-Team
          </div>
        </Card>
        <Card className="mt-3">
          <div className={"p-3"}>
            <h5>Das könnte Sie auch interessieren</h5>
            FON text FON textFON text FON text FON textFON text
            FON text FON textFON text FON text FON textFON text
            FON text FON textFON text FON text FON textFON text
            FON text FON textFON text FON text FON textFON text
          </div>
        </Card>
      </Colxx>
    </Row>

  </>
}
export default ThankYou;
