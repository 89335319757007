import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { levelTypes } from 'constants/levels';
import { ReactComponent as BookIcon } from './icons/book.svg';

import styles from './article-card.module.scss';

const ArticleCard = ({
  url,
  imageUrl,
  imageAlt,
  subTitle,
  title,
  levelLabel,
  levelType,
  readingTime,
  autoHeight = true,
}) => (
  <NavLink
    to={url}
    className={classnames(
      styles.root,
      styles[levelType],
      autoHeight && styles.autoHeight
    )}
  >
    <div className={styles.image}>
      {imageUrl && <img src={imageUrl} alt={imageAlt || title} />}
    </div>
    <div className={styles.level}>
      <div className={styles.levelLabel}>{levelLabel}</div>
    </div>
    <div className={styles.actions}>
      {readingTime && (
        <div>
          <BookIcon />
          <span className={styles.actionLabel}>{readingTime} min</span>
        </div>
      )}
    </div>
    <div className={styles.title}>
      <h4 className={styles.supTitle}>{subTitle}</h4>
      <h3 className={styles.mainTitle}>{title}</h3>
    </div>
  </NavLink>
);

ArticleCard.propTypes = {
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  levelLabel: PropTypes.string,
  levelType: PropTypes.oneOf(levelTypes),
  readingTime: PropTypes.number,
  autoHeight: PropTypes.bool,
};

export default ArticleCard;
