import {
  CHECK_SUBSCRIPTION_ID,
  CHECK_SUBSCRIPTION_ID_SUCCESS,
  CHECK_SUBSCRIPTION_ID_ERROR,
} from '../actions/types';

const INIT_STATE = {
  entry: {},
  subscriptionId: '',
  loading: false,
  error: '',
  statusMessage: 'valid.subscription.id'
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHECK_SUBSCRIPTION_ID:
      return { ...state, loading: true, error: '', statusMessage: 'valid.subscription.id' };
    case CHECK_SUBSCRIPTION_ID_SUCCESS:

      return { ...state,
        loading: false,
        entry: action.payload,
        error: '',
        statusMessage: action.payload.hasOwnProperty('uuid') ? 'valid.subscription.id' : 'not.valid.subscription.id'
      };
    case CHECK_SUBSCRIPTION_ID_ERROR:

      return {
        ...state,
        loading: false,
        user: '',
        error: action.payload.message,
        statusMessage: 'valid.subscription.id'
      };
    default:
      return { ...state };
  }
};
