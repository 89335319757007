import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull';
import { Row } from 'reactstrap';
import Slider from 'react-input-slider';
import ReactPlayer from 'react-player';
import { Colxx } from '../CustomBootstrap';
import PlayImg from '../../../assets/imgs/Play.svg';
import VolumeImg from '../../../assets/imgs/VolumeIcon.svg';
import Duration from './Duration';

class CustomAudioPlayer extends Component {
  state = {
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.5,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  };

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  componentDidMount() {
    this.load(this.props.url);
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.x) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    console.log('onPlay');
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    console.log('onEnablePIP');
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    console.log('onDisablePIP');
    this.setState({ pip: false });
  };

  handlePause = () => {
    console.log('onPause');
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.x) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    console.log('onProgress', state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = () => {
    console.log('onEnded');
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    console.log('onDuration', duration);
    this.setState({ duration });
  };

  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player));
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      duration,
      playbackRate,
      pip,
    } = this.state;

    return (
      <>
        <Row className={'text-center mobile-label-vorlese'}>
          <Colxx xxs={12}>
            <div className={'label-vorlese-audio style-font-2'}>
              Listen to the text
            </div>
          </Colxx>
        </Row>
        <Row
          className={
            'wrapper-player-vorlese text-center position-relative d-flex justify-content-center align-items-center w-100 h-100 p-0 m-0'
          }
        >
          <Colxx
            xxs={12}
            className={
              'd-flex flex-row justify-content-center align-items-center p-0 m-0'
            }
          >
            <div className="player-wrapper">
              <ReactPlayer
                ref={this.ref}
                className="react-player"
                width="100%"
                height="100%"
                url={url}
                pip={pip}
                playing={playing}
                controls={controls}
                light={light}
                loop={loop}
                playbackRate={playbackRate}
                volume={volume}
                muted={muted}
                onReady={() => console.log('onReady')}
                onStart={() => console.log('onStart')}
                onPlay={this.handlePlay}
                onEnablePIP={this.handleEnablePIP}
                onDisablePIP={this.handleDisablePIP}
                onPause={this.handlePause}
                onBuffer={() => console.log('onBuffer')}
                onSeek={(e) => console.log('onSeek', e)}
                onEnded={this.handleEnded}
                onError={(e) => console.log('onError', e)}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}
              />
            </div>
            <div className={'label-vorlese-audio style-font-2'}>
              Listen to the text
            </div>
            <div className={'vorlese-audio-controls'}>
              {/*<button onClick={this.handleStop}>Stop</button>*/}
              {/*<button onClick={this.handlePlayPause}>{playing ? 'Pause' : 'Play'}</button>*/}

              <div onClick={this.handlePlayPause}>
                {playing ? (
                  'Pause'
                ) : (
                  <img src={PlayImg} className={''} alt="Play" width={19} />
                )}
              </div>
            </div>
            <div className={'vorlese-audio-played-duration'}>
              <Duration seconds={duration * played} />
            </div>
            <div>
              {/*<input*/}
              {/*  type='range' min={0} max={0.999999} step='any'*/}
              {/*  value={played}*/}
              {/*  onMouseDown={this.handleSeekMouseDown}*/}
              {/*  onChange={this.handleSeekChange}*/}
              {/*  onMouseUp={this.handleSeekMouseUp}*/}
              {/*  className={"range-audio-player"}*/}
              {/*/>*/}

              <Slider
                styles={{
                  track: {
                    backgroundColor: '#515360',
                    height: '1px',
                    width: '155px',
                  },
                  active: {
                    backgroundColor: '#fff',
                  },
                  thumb: {
                    width: 15,
                    height: 15,
                  },
                  disabled: {
                    opacity: 0.5,
                  },
                }}
                xstep={0.1}
                xmin={0}
                xmax={1}
                axis="x"
                x={played}
                onChange={(x) => {
                  this.handleSeekChange(x);
                }}
              />
            </div>

            <div className={'vorlese-audio-remaining-duration'}>
              <Duration seconds={duration * (1 - played)} />
            </div>
            <div className={'vorlese-audio-volume-icon'}>
              <img src={VolumeImg} className={''} alt="Play" width={19} />
            </div>
            <div className={'vorlese-audio-volume-widget'}>
              <Slider
                styles={{
                  track: {
                    backgroundColor: '#515360',
                    height: '3px',
                    width: '60px',
                  },
                  active: {
                    backgroundColor: '#fff',
                  },
                  thumb: {
                    width: 15,
                    height: 15,
                  },
                  disabled: {
                    opacity: 0.5,
                  },
                }}
                xstep={0.1}
                xmin={0}
                xmax={0.999999}
                axis="x"
                x={volume}
                onChange={(x) => {
                  this.handleVolumeChange(x);
                }}
              />
              {/*<input type='range' min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange} className={"volume-line"}/>*/}
            </div>
          </Colxx>
        </Row>
      </>
    );
  }
}

export default CustomAudioPlayer;
