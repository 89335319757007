import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PacketLayout from '../layout/PacketLayout';
import SinglePacket from './packet/single/index';

class Admin extends React.Component {
  render() {
    // console.log(this.props)
    // console.log("props")

    return (
      <Suspense fallback={<div className="loading" />}>
        <Switch>
          {/*<Redirect exact from={`${match.url}/`} to={`${match.url}/start`} />*/}
          <Redirect
            exact
            from={`${this.props.match.url}/`}
            to={`${this.props.match.url}/paket/3264`}
          />

          <Route
            path={`${this.props.match.url}/paket/:nid`}
            render={(props) => (
              <PacketLayout>
                <SinglePacket {...props} />
              </PacketLayout>
            )}
          />

          {/*<Redirect to="/error" />*/}
        </Switch>
      </Suspense>
    );
  }
}

export default Admin;
