import axios from 'axios';
import {
  SPRACHEN_BASE_URL,
  SPRACHEN_USR,
  SPRACHEN_PASSWD,
} from '../constants/defaultValues';

export const axiosSprachenInstance = () => {
  try {
    const instance = axios.create({
      baseURL: SPRACHEN_BASE_URL,
      headers: { 'Content-Type': 'application/json' },
      auth: {
        username: SPRACHEN_USR,
        password: SPRACHEN_PASSWD,
      },
    });

    return instance;
  } catch (e) {
    throw e;
  }
};
