import {
  // defaultDirection,
  defaultLocale,
  // defaultColor,
  localeOptions,
  // themeColorStorageKey,
  // themeRadiusStorageKey
} from '../constants/defaultValues';

//
// export const getDateWithFormat = () => {
//   const today = new Date();
//   let dd = today.getDate();
//   let mm = today.getMonth() + 1; // January is 0!
//
//   const yyyy = today.getFullYear();
//   if (dd < 10) {
//     dd = `0${dd}`;
//   }
//   if (mm < 10) {
//     mm = `0${mm}`;
//   }
//   return `${dd}.${mm}.${yyyy}`;
// };
//
// export const getCurrentTime = () => {
//   const now = new Date();
//   return `${now.getHours()}:${now.getMinutes()}`;
// };
//

//
// export const getCurrentColor = () => {
//   let currentColor = defaultColor;
//   try {
//     if (localStorage.getItem(themeColorStorageKey)) {
//       currentColor = localStorage.getItem(themeColorStorageKey);
//     }
//   } catch (error) {
//     console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error)
//     currentColor = defaultColor
//   }
//   return currentColor;
// };
//
// export const setCurrentColor = (color) => {
//   try {
//     localStorage.setItem(themeColorStorageKey, color);
//   } catch (error) {
//     console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
//   }
// };
//
// export const getCurrentRadius = () => {
//   let currentRadius = 'rounded';
//   try {
//     if (localStorage.getItem(themeRadiusStorageKey)) {
//       currentRadius = localStorage.getItem(themeRadiusStorageKey);
//     }
//   } catch (error) {
//     console.log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error)
//     currentRadius = 'rounded'
//   }
//   return currentRadius;
// };
// export const setCurrentRadius = (radius) => {
//   try {
//     localStorage.setItem(themeRadiusStorageKey, radius);
//   } catch (error) {
//     console.log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error)
//   }
// };
//

export const calculateRelatedArticle = (seen, pool, nid) => {
  // let random = Math.floor(Math.random() * pool.length)

  let seenArr = Object.keys(seen);
  seenArr.push(nid);
  // seenArr.push(4035);
  // console.log(seenArr)
  let unseen = pool.reduce((a, c) => {
    if (seenArr.indexOf(c) === -1) {
      a.push(c);
    }

    return a;
  }, []);

  const shuffled = unseen.sort(() => 0.5 - Math.random());

  return {
    nids: shuffled.slice(0, 3).join('-'),
    // nids: unseen.slice(0,3).join("-"),
    poolEnd: unseen.length === 3,
  };
};
export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  try {
    return localStorage.getItem('disney_current_user');
    // let u = localStorage.getItem('disney_current_user');
    // return JSON.parse(u);
  } catch (error) {
    console.log(
      '>>>> src/redux/auth/reducer.js : getCurrentUser -> error',
      error
    );
    return '';
  }
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('disney_current_user', JSON.stringify(user.uid));
    } else {
      localStorage.removeItem('disney_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getCurrentUserObject = () => {
  try {
    let data = localStorage.getItem('disney_current_user_object');
    return JSON.parse(data) || {};
  } catch (error) {
    console.log(
      '>>>> src/redux/auth/reducer.js : getCurrentUserObject -> error',
      error
    );
    return '';
  }
};

export const setCurrentUserObject = (user) => {
  try {
    if (user) {
      localStorage.setItem('disney_current_user_object', JSON.stringify(user));
    } else {
      localStorage.removeItem('disney_current_user_object');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentUserObject -> error',
      error
    );
  }
};

export const getUserDataFirebaseAuth = (userObject) => {
  try {
    if (
      userObject.hasOwnProperty('displayName') ||
      userObject.hasOwnProperty('email')
    ) {
      let userInfo = {
        displayName: userObject.displayName,
        email: userObject.email,
        emailVerified: userObject.emailVerified,
        photoURL: userObject.photoURL,
        isAnonymous: userObject.isAnonymous,
        uid: userObject.uid,
        providerData: userObject.providerData,
      };

      return userInfo;
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const updateUrl = (pathname, entry, parsed, history) => {
  if (parsed.hasOwnProperty('aboId')) {
    if (entry.hasOwnProperty('AboId')) {
      if (parsed['aboId'] !== entry['AboId']) {
        history.push({
          pathname: pathname,
          search: 'aboId=' + entry['AboId'],
        });
      }
    } else {
      history.push({
        pathname: pathname,
        search: '',
      });
    }
  } else {
    if (entry.hasOwnProperty('AboId')) {
      history.push({
        pathname: pathname,
        search: 'aboId=' + entry['AboId'],
      });
    }
  }
};

export const getSeenPackets = () => {
  try {
    let data = localStorage.getItem('zeit_sprachen_seen_packets');
    return JSON.parse(data) || {};
  } catch (error) {
    console.log(
      '>>>> src/redux/auth/reducer.js : getCurrentUserObject -> error',
      error
    );
    return '';
  }
};

export const setSeenPackets = (packet) => {
  try {
    if (packet) {
      localStorage.setItem(
        'zeit_sprachen_seen_packets',
        JSON.stringify(packet)
      );
    } else {
      localStorage.removeItem('zeit_sprachen_seen_packets');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentUserObject -> error',
      error
    );
  }
};
