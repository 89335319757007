import React from 'react';
import Sticky from 'react-stickynode';
import exercises from 'assets/imgs/exercises.svg';

import styles from './exercise-section.module.scss';

const SectionExercises = () => {
  return (
    <>
      <div className={styles.image}>
        <img src={exercises} alt="Exercises" />
      </div>
      <Sticky enabled={true} top={0} innerZ={8888888}>
        <div className={styles.bar} />
      </Sticky>
    </>
  );
};

export default SectionExercises;
