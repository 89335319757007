import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import openBookIcon from './icons/open-book.svg';
import chatBubblesIcon from './icons/chat-bubbles.svg';

import styles from './packet-navigator.module.scss';

const PacketNavigator = ({
  levelLabel,
  levelType,
  readingTime,
  exerciseTime,
  theme,
}) => (
  <div
    className={classnames(styles.root, {
      [styles.themeGold]: theme === 'gold',
      [styles.themeBlue]: theme === 'blue',
      [styles.themeTiffany]: theme === 'tiffany',
    })}
  >
    <div
      className={classnames(styles.level, {
        [styles.levelTypeEasy]: levelType === 'easy',
        [styles.levelTypeMedium]: levelType === 'medium',
        [styles.levelTypeAdvanced]: levelType === 'advanced',
      })}
    >
      <div className={styles.levelLabel}>{levelLabel}</div>
    </div>
    <div className={styles.actions}>
      <AnchorLink href="#vorleseID" className={styles.action}>
        <img src={openBookIcon} alt="Open book icon" role="presentation" />
        <span className={styles.actionLabel}>reading time</span>
        <strong>{readingTime}min</strong>
      </AnchorLink>
      <div className={styles.action}>
        <img src={chatBubblesIcon} alt="Open book icon" role="presentation" />
        <span className={styles.actionLabel}>exercise part</span>
        <strong>{exerciseTime}min</strong>
      </div>
    </div>
  </div>
);

PacketNavigator.propTypes = {
  levelLabel: PropTypes.string.isRequired,
  levelType: PropTypes.oneOf(['easy', 'medium', 'advanced']),
  readingTime: PropTypes.number,
  exerciseTime: PropTypes.number,
  theme: PropTypes.oneOf(['gold', 'blue', 'tiffany']),
};

export default PacketNavigator;
