import enLang from './entries/en-US';
import esLang from './entries/es-ES';
import deLang from './entries/de-DE';

const AppLocale = {
  en: enLang,
  es: esLang,
  de: deLang,
};

export default AppLocale;
