import React from 'react';
import LayoutWidth from '../../../../layout/LayoutWidth';
import ParallaxedImage from 'components/parallaxed-image/parallaxed-image';
import Markup from 'components/markup/markup';

const isText = (param) => param === 'text';
const isImage = (param) => param === 'image';

const LayoutedText = ({ content }) => (
  <LayoutWidth>
    <Markup content={content} />
  </LayoutWidth>
);

const selectComponent = ({ type, data, idx }) => {
  if (isText(type)) {
    return <LayoutedText content={data} key={`layouted-text-${idx}`} />;
  }

  if (isImage(type)) {
    return (
      <ParallaxedImage
        imageUrl={data.url}
        orientation={data.type}
        key={`parallaxed-image-${idx}`}
      />
    );
  }

  return () => null;
};

const ArticleBody = ({ body }) => {
  return (
    <>
      {body.map((el, idx) =>
        selectComponent({ type: el.type, data: el.value, idx })
      )}
    </>
  );
};

export default ArticleBody;
