import React from 'react';
import Sticky from 'react-stickynode';
import LayoutWidth from '../../../layout/LayoutWidth';
import ScrollPageIndicator from '../scroll-progress-indicator';
import CustomAudioPlayer from './CustomAudioPlayer';

const Vorlese = ({ data }) => (
  <Sticky enabled={true} top={0} innerZ={1111} className="position-relative">
    <div
      id="vorleseID"
      className="page-header-vorlese clear-filter d-flex justify-content-center align-self-center"
    >
      <LayoutWidth>
        {data.url ? (
          <div className="wrapper-vorlese h-100">
            <CustomAudioPlayer url={data.url} />
          </div>
        ) : null}
      </LayoutWidth>
    </div>
    <ScrollPageIndicator />
  </Sticky>
);

export default Vorlese;
