import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import LangContext from '../../../context/LangContext';
import { Modal, Button } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import comingSoonImage from '../../../assets/imgs/coming-soon-image.svg';
import closeIcon from '../../../assets/imgs/streamline-icon-close@24x24.svg';

import styles from './simple-modal.module.scss';

const SimpleModal = (props) => {
  const { ToggleComponent } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { messages } = useContext(LangContext);

  return (
    <>
      <span onClick={toggle}>
        <ToggleComponent />
      </span>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={styles.root}
        zIndex={100000000}
      >
        <div className={styles.container}>
          <Button color="link" onClick={toggle} className={styles.close}>
            <img src={closeIcon} alt="Close the popup" />
          </Button>
          <header className={styles.header}>
            <h2>
              <IntlMessages id="popup.title" />
            </h2>
          </header>
          <p>
            <img
              src={comingSoonImage}
              alt={messages['popup.image.alt-text']}
              className={styles.image}
            />
          </p>
          <p className="style-font-3">
            <IntlMessages id="popup.promise" />
          </p>
          <Button color="secondary" onClick={toggle} className={styles.button}>
            <IntlMessages id="popup.ok-button.text" />
          </Button>
        </div>
      </Modal>
    </>
  );
};

SimpleModal.propTypes = {
  ToggleComponent: PropTypes.elementType.isRequired,
};

export default SimpleModal;
