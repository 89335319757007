import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CONFIRMATION_USER,
  CONFIRMATION_USER_SUCCESS,
  CONFIRMATION_USER_ERROR,
  FETCH_USER_DATA,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_ERROR,
  USR_MGMT,
  USR_MGMT_SUCCESS,
  USR_MGMT_ERROR,
} from '../actions/types';
import { getCurrentUser } from '../helpers/Utils';

const INIT_STATE = {
  object: {},
  user: getCurrentUser(),
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  errorRegistration: '',
  errorStorage: '',
  errorConfirmation: '',
  errorPasswordForgot: '',
  errorPasswordReset: '',
  confirmed: 1,
};
// confirmed: 1 == initialState
// confirmed: 2 == successfully confirmed
// confirmed: 3 == already confirmed

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USR_MGMT:
      return { ...state, loading: true, errorConfirmation: '' };
    case USR_MGMT_SUCCESS:
      return {
        ...state,
        loading: false,
        user: '',
        confirmed: action.payload ? 2 : 3,
        errorConfirmation: '',
        object: {},
      };
    case USR_MGMT_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
        object: {},
        errorConfirmation: action.payload,
      };

    case FETCH_USER_DATA:
      return { ...state, loading: true, errorStorage: '' };
    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        object: action.payload,
        errorStorage: '',
      };
    case FETCH_USER_DATA_ERROR:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        errorStorage: action.payload.message,
      };

    case CONFIRMATION_USER:
      return { ...state, loading: true, error: '' };
    case CONFIRMATION_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.uid, error: '' };
    case CONFIRMATION_USER_ERROR:
      // console.log('I am hxxxere')
      return {
        ...state,
        loading: false,
        user: '',
        error: action.payload.message,
      };

    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      let userInfo = {
        displayName: action.payload.displayName,
        email: action.payload.email,
        // emailVerified: action.payload.emailVerified,
        emailVerified: true,
        photoURL: action.payload.photoURL,
        isAnonymous: action.payload.isAnonymous,
        uid: action.payload.uid,
        providerData: action.payload.providerData,
      };
      return {
        ...state,
        loading: false,
        user: action.payload.uid,
        object: userInfo,
        error: '',
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
        error: action.payload.message,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, errorPasswordForgot: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        errorPasswordForgot: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        errorPasswordForgot: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, errorPasswordReset: '' };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: '',
        errorPasswordReset: '',
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetPasswordCode: '',
        errorPasswordReset: action.payload.message,
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.uid, error: '' };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return { ...state, user: null, error: '' };
    default:
      return { ...state };
  }
};
