import React from 'react';

const Markup = ({ content, className = '', as = 'div' }) => {
  const Element = as;

  return (
    <Element
      className={className}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default Markup;
